<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
       <a-form-item :label="item.label" v-for="(item, index) of formData" :key="index" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" placeholder="请输入相册名称" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择关联班级" mode="multiple"
        v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
        <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="关联班级" :label-col="labelCol" :wrapper-col="{ span: 18 }">
        <a-button icon="plus" type="dashed" @click="shwoClassesModal">关联班级</a-button>
        <div>
          <a-tag closable @close="handleCloseClasses(index)" v-for="(item,index) in classesList" :key='item.class_id'>{{item.class_name}}</a-tag>
        </div>
      </a-form-item>
    </a-form>
    <classesModal v-if="isClassesModal" :item="modalData"/>
  </a-modal>
</template>

<script>
import classesModal from '@/views/students/zcxy/classesModal'

const formData = [
  {
    name: 'album_name',
    label: '相册名称',
    type: 'input',
    rules: [{ required: true, message: '请输入相册名称!' }]
  },
  // {
  //   name: 'classes_id',
  //   label: '关联班级',
  //   type: 'select',
  //   rules: [{ required: true, message: '请选择关联班级!' }],
  //   items:{
  //     data: 'classes',
  //     label: 'filter_name',
  //     value: 'class_id'
  //   }
  // }
]

export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    item: Object
  },
 components: {
    classesModal,
  },
  data() {
    return {
      formData,
      isClassesModal:0,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增相册',
      form: this.$form.createForm(this),
      seleteItems: {
        classes:[]
      },
      modalData: {},
      classesList:[],
    }
  },

  async created() {
    this.visible = true
    this.$ls.remove('localClassesList')
    this.getClasses()
    await this.$nextTick()
    const { item, authArr } = this
    if (item) {
      this.ModalTitle = '编辑相册'
      this.classesList = item.album_classes
      this.$ls.set('localClassesList', this.classesList)
      this.form.setFieldsValue({album_name:item.album_name})
    }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClasses() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.seleteItems.classes = res.data
    },
    async handleOk() {
      const { item, form } = this
      let params = await form.validateFields()
      if(this.classesList.length == 0){
        this.$message.warning('请选择班级!!')
      }
      this.confirmLoading = true
      if(item){
        params.album_id = item.album_id
      }
      let classesList = []
      this.classesList.forEach(item=>{
        classesList.push(item.class_id)
      })
      params.classes_id = classesList
      await this.$store.dispatch(item ? 'classesAlbumUpdateAction' : 'classesAlbumAddAction', params)
        .then(res=>{
          this.$message.success('操作成功~')
          this.parent.hideModal(1)
        })
        .catch(err=>{
          this.$message.error('操作失败~')
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.parent.hideModal()
    },
    shwoClassesModal(){
      this.isClassesModal = true
    },
    hideClassesModal(type){
      if(type == 1){
        let classesList = this.$ls.get('localClassesList') || []
        let list = []
        if(classesList.length > 0){
          classesList.forEach(item=>{
            list.push(item.class_id)
          })
          list = Array.from(new Set(list))
          list.forEach((item,index)=>{
            classesList.forEach(it=>{
              if(item == it.class_id){
                list[index] = it
              }
            })
          })
          this.$ls.set('localClassesList', list)
          this.classesList = list
        }
      }
      this.isClassesModal = false
    },
    handleCloseClasses(index){
      this.classesList.splice(index,1)
      this.$ls.set('localClassesList', this.classesList)
    }
  }
}
</script>
